import { ref, onMounted, onBeforeUnmount } from 'vue';

export function usePopLoader(className, delay = 100, elementId) {
    const popLoaderElement = ref(null);
    let timer = null;

    const startTimer = () => {
        timer = setTimeout(() => {
            if (popLoaderElement.value) {
                console.log("loading", timer);
            } else {
                if (elementId) {
                    const targetElement = document.getElementById(elementId);
                    if (targetElement) {
                        targetElement.scrollIntoView();
                        clearTimer();
                    }
                }
            }
        }, delay);
    };

    const clearTimer = () => {
        if (timer) {
            clearTimeout(timer);
        }
    };

    onMounted(() => {
        popLoaderElement.value = document.querySelector(`.${className}`);
        startTimer();
    });

    onBeforeUnmount(() => {
        clearTimer();
    });

    return {
        popLoaderElement,
    };
}
