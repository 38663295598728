<template>
    <div class="modal-form">
        <div class="form-title">Зміна паролю</div>
        <div class="form-anons">На someemail@gmail.com вислане <br />посилання для зміни пароля </div>
        <button class="to-login button-2">Я згадав пароль</button>
    </div>
</template>

<script>
export default {}
</script>
