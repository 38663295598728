<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <inertia-link href="/catalog">{{ $t('Каталог') }}</inertia-link>
            <inertia-link v-for="(parent) in parents" :key="'parent-' + parent.id" :href="parent.url">
                {{ parent.name_short || parent.name }}
            </inertia-link>
            <span>{{ category.name_short || category.name }}</span>
        </div>

        <div class="h1-containter d-lg-flex align-items-end">
            <h1>{{ $page.props.seo.h1 }}</h1>
            <div class="h1-results">Знайдено {{ total }}</div>
        </div>

        <div class="catalog-list inner" id="category-catalog-items">

            <div class="catalog-list-top d-lg-flex align-items-center justify-content-between">
                <div>
                    <a href="#" @click.prevent="resetFilters">{{ $t('Скинути фільтри') }}</a>
                </div>

                <div class="catalog-sort d-md-flex align-items-center">
                    <span class="data">{{ $t('Сортувати') }}:</span>
                    <v-select
                        :options="sortOptions"
                        :reduce="sortOptions => sortOptions.id"
                        :clearable="false"
                        :searchable="!isMobile"
                        v-model="sortBy"
                        placeholder="Оберить опцію"
                        @option:selected="selectValue"
                    />
                </div>
            </div>
            <div class="row gutters-6">
                <div class="col col-filter">
                    <catalog-filter-aside />
                </div>
                <div class="col">
                    <div class="catalog-items">

                        <div class="row gutters-6">
                            <template v-if="items?.length">
                                <div v-for="product in items" class="col-12 col-md-6  col-lg-3">

                                    <product-box :product="product" :key="'product-'+product.id" />

                                </div>
                            </template>
                            <template v-else>
                                <div class="col-12 col-md-6  col-lg-3">
                                    {{ $t('На жаль за вашим запитом товарів немає') }}
                                </div>
                            </template>


                        </div>

                        <pagination :paginator="products" :on-link-click="sendFilterForm" @load-more="loadMore" />

                        <ask-question class="inner" />

                    </div>
                </div>
            </div>
        </div>

        <review-carousel v-if="reviews.length" :reviews="reviews" />

        <discount-group :discount-groups="discountGroups" />

        <div v-if="$page.props.seo.text" class="bottom-description">
           <div class="text-container" v-html="$page.props.seo.text"></div>
        </div>
    </div>

</template>

<script setup>
import { defineAsyncComponent } from "vue";
import { usePopLoader } from "../../scrollToTop";

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
const Pagination = defineAsyncComponent(() => import("../../Components/Pagination.vue"))
const FormSelect = defineAsyncComponent(() => import("../../Components/Form/FormSelect.vue"))
const CatalogFilterAside = defineAsyncComponent(() => import("../../Components/Catalog/CatalogFilterAside.vue"))
const DiscountGroup = defineAsyncComponent(() => import("../../Components/Widgets/DiscountGroup.vue"))
const ReviewCarousel = defineAsyncComponent(() => import("../../Components/ReviewCarousel.vue"))
const AskQuestion = defineAsyncComponent(() => import("../../Components/Widgets/AskQuestion.vue"))
const ProductBox = defineAsyncComponent(() => import("../../Components/Product/ProductBox.vue"))
</script>

<script>
import { nextTick } from "vue";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
    components: {
        vSelect,
    },
    props: {
        reviews: {
            type: Array,
            default() {
                return []
            }
        },
        requestFilters: {
            type: Object,
            default() {
                return {
                    categories: [],
                    brands: [],
                    sell_types: [],
                    price: {
                        min: 0,
                        max: 0,
                    },
                    sorBy: 'default:asc',
                }
            }
        },
        filters: {
            type: Object,
            default() {
                return {
                    characteristics: [],
                }
            }
        },
        category: {
            type: Object,
            default() {
                return {}
            }
        },
        parents: {
            type: Array,
            default() {
                return []
            }
        },
        discounts: {
            type: Array,
            default() {
                return []
            }
        },
        discountGroups: {
            type: Array,
            default() {
                return []
            }
        },
        products: {
            type: Object,
            default() {
                return {
                    data: [],
                    links: [],
                }
            }
        },
    },
    created() {
        this.$store.commit('catalog/SET_CATEGORY', this.category || {});
        this.$store.commit('catalog/SET_PARENTS', this.parents || {});

        this.$store.dispatch('catalog/loadFilters', this.requestFilters || {});

        this.$store.commit('catalog/SET_ITEMS', this.products.data || []);
        this.$store.commit('catalog/SET_PAGINATOR', this.products || {});
    },
    mounted() {

        let tagScript = document.createElement('script')
        tagScript.setAttribute('type', "application/ld+json")
        tagScript.setAttribute('id', "ld-json-catalog-breadcrumbs")

        let step = 3
        let section = ''
        for (let item of this.parents) {
            section += `{
                        "@type": "ListItem",
                        "position": ${step},
                        "name": "${item.name}",
                        "item": "${item.url}"
                    },`
            step++
        }

        tagScript.text = `    {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "${this.category.url}",
            "name": "${this.category.name}",
            "description": "${this.category.seo_description}",
            "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "position": 1,
                        "name": "Головна",
                        "item": "https://etabletka.ua/"
                    },
                    {
                        "@type": "ListItem",
                        "position": 2,
                        "name": "Каталог",
                        "item": "https://etabletka.ua/catalog"
                    },
                    ${section}
                    {
                        "@type": "ListItem",
                        "position": ${step},
                        "name": "${this.category.name}",
                        "item": "${this.category.url}"
                    }
                ]
            }
        }`
        document.head.appendChild(tagScript)
    },
    beforeUnmount() {
        let tagScript = document.getElementById("ld-json-catalog-breadcrumbs");
        tagScript.remove();
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 768;
        },
        sortBy: {
            get() {
                return this.$store.state.catalog.filterForm.sortBy;
            },
            set(value) {
                this.$store.commit('catalog/UPDATE_FILTER_FORM', {sortBy: value});
            }
        },
        total() {
            return this.$store.getters['catalog/total'];
        },
        sortOptions() {
            return this.$store.state.catalog.sortOptions.map(item => ({
                label: item.text,
                id: item.value
            }))
        },
        items() {
            return this.$store.state.catalog.items;
        }
    },
    methods: {
        scrollToTop() {
            nextTick(() => {
                const element = document.getElementById('category-catalog-items');

                if (element) {
                    element.scrollIntoView({behavior: "smooth"})
                }
            })
        },
        resetFilters() {
            this.$store.dispatch('catalog/resetFilters')
            this.scrollToTop()
        },
        sendFilterForm(url = null) {
            this.$store.dispatch('catalog/loadProducts', url)
            this.scrollToTop()
        },
        loadMore() {
            this.$store.dispatch('catalog/loadMore');
        },
        selectValue(data) {
            if (data.id) {
                this.sortBy = data.id
                this.$store.dispatch('catalog/updateSortBy', this.sortBy)
                this.sendFilterForm()
            }
        }
    },
    watch: {
        products() {
            this.$store.commit('catalog/SET_ITEMS', this.products.data || []);
            this.$store.commit('catalog/SET_PAGINATOR', this.products || {});
        },
        category() {
            this.$store.commit('catalog/SET_CATEGORY', this.category || {});
            this.$store.dispatch('catalog/loadFilters', this.requestFilters || {})
        },
        parents() {
            this.$store.commit('catalog/SET_PARENTS', this.parents || {});
        },

    }
}
</script>

<style scoped>

</style>
