<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <inertia-link v-if="rootCategory" :href="route('frontend.blog.index')">{{ $t('Блог') }}</inertia-link>
            <span>{{ rootCategory?.title || $t('Блог') }}</span>
        </div>
        <div class="h1-containter d-md-flex">
            <h1>{{ $page.props.seo.h1 }}</h1>
        </div>
        <div class="blog-list">
            <div v-if="blogCategories.length" class="blog-tags row no-gutters">
                <inertia-link v-for="category in blogCategories" :key="'cat-'+category.id" :href="category.url"
                              class="tag health">
                    {{ category.title }}
                </inertia-link>
            </div>
            <div class="row gutters-17 row-cols-1 row-cols-md-2 row-cols-lg-3">
                <div v-for="post in posts.data" class="col">
                    <post-item :post="post" :key="'post-'+post.id" />
                </div>
            </div>


            <pagination :paginator="posts" @load-more="loadMore" />

        </div>

        <div v-if="$page.props.seo.text" class="bottom-description">
            <div class="text-collapse">
                <div class="text-container" v-html="$page.props.seo.text"></div>
                <a href="#" class="link text-see">Показати все</a>
            </div>
        </div>
    </div>

</template>

<script>

import {defineAsyncComponent} from "vue";
export default {
    components: {
        PostItem: defineAsyncComponent(() => import("../../Components/Blog/PostItem.vue")),
        Pagination: defineAsyncComponent(() => import("../../Components/Pagination.vue")),
    },
    props: {
        rootCategory: Object,
        blogCategories: [Object, Array],
        blogPosts: [Object, Array],
    },
    data() {
        return {
            loadMoreItems:  15,
            posts: this.blogPosts
        }
    },
    methods: {
        loadMore() {
            if (this.posts.next_page_url === null) {
                return
            }

            const updatedUrl = this.posts?.first_page_url.replace(/(page=)[^&]*/, `$1${this.posts?.current_page}`);
            this.loadMoreItems = this.loadMoreItems + 15

            this.$inertia.get(updatedUrl, {items: this.loadMoreItems}, {
                preserveState: true,
                preserveScroll: false,
                onSuccess: (data) => {
                    this.posts = data.props.blogPosts
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
