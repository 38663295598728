<template>
    <div class="modal-form">
        <div class="form-icon">
            <VIcon icon="form" />
        </div>

        <div class="form-title">Придумайте новий пароль</div>

        <form @submit.prevent="submitForm" class="form">
            <form-input v-model="form.password"
                        :error="errors.password"
                        :label="'Пароль'"
                        :label-class="'label'"
                        :required="true"
                        type="password"
            />
            <form-input v-model="form.password_confirmation"
                        :error="errors.password_confirmation"
                        :label="'Повторіть пароль'"
                        :label-class="'label'"
                        :required="true"
                        type="password"
            />
            <button class="submit button">Зберегти</button>
        </form>
        <div class="form-bottom">
            <button @click.prevent="$inertia.modal('/auth/login')" class="to-login form-link">Увійти</button>
            <div class="form-bottom__text up">
                Продовжуючи, Ви приймаєте умови
                <a @click.prevent="$inertia.modal('/auth/login')" href="#">угоди користувача</a>
            </div>
            <button @click.prevent="resetPhoneNumber" class="to-register form-link">
                Вказати інший номер телефону
            </button>
        </div>
    </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));

export default {
    components: {FormInput},
    props: {
        errors: {},
        phone: {},
        code: {},
        code_generated_at: {},
    },
    data() {
        return {
            form: {
                name: '',
                phone: this.phone,
                email: '',
                password: '',
                password_confirmation: '',
            },
        }
    },
    mounted() {

    },
    methods: {
        submitForm(){
            this.$inertia.modal('/auth/password/reset', 'post', this.form, {
                only: ['user']
            })
        },

        resetPhoneNumber(){
            this.$inertia.modal('/auth/password/reset', 'delete')
        },
    }
}
</script>

<style scoped>

</style>
