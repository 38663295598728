import { onMounted, onBeforeUnmount } from 'vue';

export function useRobotNoIndexFollow() {
    const tagId = 'robot-noindex-nofollow';

    function addMetaTag() {
        const tagNoIndexFollow = document.createElement('meta');
        if (tagNoIndexFollow) {

            tagNoIndexFollow.setAttribute('id', tagId);
            tagNoIndexFollow.setAttribute('name', 'robots');
            tagNoIndexFollow.setAttribute('content', 'noindex, nofollow');
            document.head.appendChild(tagNoIndexFollow);
        }
    }

    function removeMetaTag() {
        const tagNoIndexFollow = document.getElementById(tagId);
        if (tagNoIndexFollow) {
            tagNoIndexFollow.remove();
        }
    }

    onMounted(addMetaTag);
    onBeforeUnmount(removeMetaTag);
}
