<template>
    <div class="modal-form">
        <div class="form-icon">
            <VIcon icon="form" />
        </div>

        <div class="form-title">Персональні дані</div>

        <form @submit.prevent="submitForm" class="form">
            <form-input v-model="form.name"
                        :error="errors.name"
                        :label="'Ім’я'"
                        :label-class="'label'"
                        :required="true"
            />
            <form-input v-model="form.phone"
                        :error="errors.phone"
                        :label="$t('Номер телефону')"
                        :input-class="'phone'"
                        :default-phone="phone"
                        :disabled="true"
                        :ok="true"
                        :is-phone="true"
                        :maxlength="19"
                        :required="true"
            />
            <form-input v-model="form.password"
                        :error="errors.password"
                        :label="'Пароль'"
                        :label-class="'label'"
                        :required="true"
                        :type="passwordType"
                        :show-password-button="true"
            />
            <form-input v-model="form.password_confirmation"
                        :error="errors.password_confirmation"
                        :label="'Повторіть пароль'"
                        :label-class="'label'"
                        :required="true"
                        :type="passwordType"
            />
            <div class="input-container">
                <div class="checkbox">
                    <input id="show_password" type="checkbox" @change="showPassword" :checked="passwordType !== 'password'">
                    <label for="show_password">{{ $t('Показати пароль') }}</label>
                </div>
            </div>

            <button class="submit button">Зареєструватися</button>
        </form>
        <div class="form-bottom">
            <button @click.prevent="$inertia.modal('/auth/login')" class="to-login form-link">Увійти</button>
            <div class="form-bottom__text up">
                Продовжуючи, Ви погоджуєтесь
                <a target="_blank" :href="route('frontend.page.index', 'ugoda-koristuvaca')">з умовами угоди користувача</a>
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const VIcon = defineAsyncComponent(() => import("@/frontend/Components/Common/VIcon.vue"));
const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));

export default {
    components: { FormInput, VIcon },
    props: {
        errors: {
            type: [Object, Array]
        },
        phone: {
            type: [Object, String]
        },
        code: {
            type: [Object, String]
        },
        code_generated_at: {
            type: [Object, String]
        },
    },
    data() {
        return {
            form: {
                name: '',
                phone: this.phone,
                password: '',
                password_confirmation: '',
            },
            passwordType: 'password',
        }
    },
    mounted() {
        this.$emit('update:modelValue', this.phone)
    },
    methods: {
        submitForm() {
            this.$gtag.event('sign_up', {
                method: this.phone
            });

            this.$inertia.modal('/auth/register/complete', 'post', this.form, {
                only: ['user']
            })
        },

        resetPhoneNumber(){
            this.$inertia.modal('/auth/register/reset', 'delete')
        },
        showPassword() {
            this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
        }
    }
}
</script>

<style scoped>

</style>
