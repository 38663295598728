<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <inertia-link href="/catalog/sales">Знижки</inertia-link>
            <inertia-link :href="'/catalog/discount/'+discountGroup.slug">{{ discountGroup.title }}</inertia-link>
            <span>{{ discount.catalog_title || discount.title }}</span>
        </div>
        <div class="action-detail ">
            <div class="h1-containter d-md-flex">
                <h1 class="h1-small">{{ discount.catalog_title || discount.title }}</h1>
            </div>
            <div class="action-container d-md-flex justify-content-between align-items-start">
                <div class="action-detail__image">
                    <img :src="discount.image_url" alt="" class="lozad">
                </div>
                <div class="action-detail__info">
                    <div v-if="discount.time_active">
                        <div class="action-date d-inline-flex">Акція діє до {{ discount.end_date }}</div>
                        <div class="action-termin">
                            <div class="data ">До кінця акції :</div>
                            <div v-if="discount.days_to_end > 0" class="value">
                                залишилось <span>{{ discount.days_to_end }}</span> днів
                            </div>
                            <div v-if="discount.days_to_end === 0 && timeToEnd > 0" class="value">
                                залишилось {{ timer }}
                            </div>
                            <div v-if="discount.days_to_end === 0 && timeToEnd <= 0" class="value">
                                акція завершилась
                            </div>
                        </div>
                    </div>

                    <div class="action-share" :class="{noborder: !discount.time_active}">
                        <div class="data">Поділитись акцією:</div>
                        <div class="value d-flex">
                            <ShareNetwork class="fb d-flex align-items-center justify-content-center"
                                network="facebook"
                                :url="url"
                                hashtags="etabletka,promotion,discounts"
                            >
                                <span class="ic icon-facebook"></span>
                            </ShareNetwork>
                            <ShareNetwork class="tw d-flex align-items-center justify-content-center"
                                          network="twitter"
                                          :url="url"
                                          hashtags="etabletka,promotion,discounts"
                            >
                                <span class="ic icon-twitter"></span>
                            </ShareNetwork>
                            <ShareNetwork class="tg d-flex align-items-center justify-content-center"
                                          network="Telegram"
                                          :url="url"
                                          hashtags="etabletka,promotion,discounts"
                            >
                                <span class="ic icon-telegram">
                                    <span class="path1"></span>
                                    <span class="path2">
                                    </span>
                                </span>
                            </ShareNetwork>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="catalog-slider__container">
            <div class="slider-top d-flex align-items-center justify-content-between">
                <div class="title small-title">Товари які беруть участь в акції</div>
                <div class="navs catalog-slider__navs d-flex"></div>
            </div>
            <div class="catalog-list">
                <div class="catalog-items">

                    <div class="row gutters-6">
                        <template v-if="items.length">
                            <div v-for="product in items" class="col-12 col-md-6  col-lg-3">
                                <product-box :product="product" :key="'product-'+product.id" />
                            </div>
                        </template>
                        <template v-else>
                            <div class="col-12 col-md-6  col-lg-3">
                                {{ $t('На жаль за вашим запитом товарів немає') }}
                            </div>
                        </template>


                    </div>

                    <pagination :paginator="products" @load-more="moreProducts" hide-more-btn />

                </div>
            </div>
        </div>
    </div>

</template>


<script setup>
import { useRobotNoIndexFollow } from "../metaTags"
import { usePopLoader } from "../../scrollToTop";
import { defineAsyncComponent } from "vue";

const Pagination = defineAsyncComponent(() => import("../../Components/Pagination.vue"))
const ProductBox = defineAsyncComponent(() => import("../../Components/Product/ProductBox.vue"))

const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
useRobotNoIndexFollow()
</script>

<script>
export default {
    name: "DiscountDetails",
    props: {
        discountGroup: Object,
        discount: Object,
        products: Object,
    },
    data() {
        return {
            interval: null,
            timeToEnd: this.discount.time_to_end || 0,
            url: null
        }
    },
    computed: {
        items() {
            return this.products?.data || []
        },
        timer() {
            if (this.timeToEnd > 0) {
                const hours = Math.floor((this.timeToEnd % 86400) / 3600);
                const minutes = Math.floor((this.timeToEnd % 3600) / 60);
                const seconds = Math.floor(this.timeToEnd % 60);
                return hours + ':' + (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds);
            }
            return '';
        }
    },
    mounted() {
        this.url = document.URL
        this.interval = setInterval(() => {
            this.timeToEnd--;
        }, 1000);
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
    methods: {
        moreProducts() {

        }
    }
}
</script>

<style scoped>

</style>
