<template>
    <div class="modal-form">
        <div class="form-icon">
            <VIcon icon="form" />
        </div>
        <div class="form-title">Реєстрація</div>
        <div class="form-anons">Введіть свій номер телефону</div>

        <form @submit.prevent="submit">
            <!-- phone -->
            <form-input v-model="form.phone"
                        :error="errors.phone"
                        :label="$t('Номер телефону')"
                        :input-class="{ phone: true }"
                        :is-phone="true"
                        :maxlength="19"
                        :required="true"
                        @blur="lostFocus"
            />
            <div v-if="!isValidCode && form.phone.length >= 3" class="error-phone-exist">
                <span>Невалідний код мобільного оператора</span>
            </div>
            <div v-if="exist" class="error-phone-exist">
                <span>Цей номер вже зареєстрованний</span>
                <button @click.prevent="$inertia.modal('/auth/login')" class="to-login form-link">Увійти</button>
            </div>
            <div class="checkbox mb-3">
                <div v-if="errors.confirm" class="input-container error">
                    <div class="error">{{ errors.confirm }}</div>
                </div>
                <input v-model="form.confirm" type="checkbox" value="1" name="confirm" id="umovi" @click="lostFocus">
                <label for="umovi">Я приймаю умови
                    <a target="_blank" href="/page/politika-konfidenciinosti">Політики конфедеційності</a>
                </label>
            </div>
            <!-- submit -->
            <button type="submit" :disabled="exist || !validPhoneLength || form.processing || !form.confirm || !form.phone"
                    class="submit button to-sms">Перейти далі
            </button>
        </form>

        <div class="form-bottom">
            <button @click.prevent="$inertia.modal('/auth/login')" class="to-login form-link">Ви вже зареєстровані? Увійти</button>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const VIcon = defineAsyncComponent(() => import("@/frontend/Components/Common/VIcon.vue"));
const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));

export default {
    components: { FormInput, VIcon },
    props: {
        errors: Array,
        phone: String,
    },
    data() {
        return {
            form: {
                phone: this.phone,
                confirm: false,
            },
            exist: false
        }
    },
    computed: {
        validPhoneLength() {
            return this.form.phone.length === 19 && this.isValidCode
        },
        isValidCode() {
            const codes = ["050", "066", "095", "099", "063", "073", "093", "067", "068", "096", "097", "098", "091", "092", "094"];

            return codes.includes(this.form.phone.substring(5, 8))
        }
    },
    mounted() {
        if(this.phone)
        {
            this.submit()
        }
    },
    methods: {
        submit() {
            this.$inertia.modal('/auth/register/generate', 'post', this.form)
        },
        lostFocus() {
            if (!this.validPhoneLength) return
            axios.post('/api/v1/check/phone', {
                phone: this.form.phone.replace(/[()\-\s]/g, '')
            }).then(response => {
                this.exist = response.data.exist
            }).catch(e => {
                if (!axios.isCancel(e)) {
                    console.log(e);
                }
            });
        }
    }
}
</script>

<style scoped>
.error-phone-exist {
    color: red;
    margin-top: -10px;
    margin-bottom: 10px;
    .to-login {
        margin-left: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #10A5AA;
    }
}
</style>


