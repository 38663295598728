<template>
    <div class="modal-form">
        <div class="form-title feed">Додати коментар</div>
        <form :action="route('frontend.blog.comment.store')" method="post" class="form"
              @submit.prevent="form.post(route('frontend.blog.comment.store'))">
            <input type="hidden" name="parent_id" :value="parentId">
            <input type="hidden" name="post_id" :value="postId">
            <input type="hidden" name="category" :value="category">
            <div class="input-container">
                <label>Ваше Им’я</label>
                <input type="text" name="name" class="input important" placeholder="" v-model="form.name">
                <div v-if="form.errors.name" class="error">{{ form.errors.name }}</div>
            </div>
            <div class="input-container">
                <label>Ваш email</label>
                <input type="text" name="email" class="input email important" v-model="form.email">
                <div v-if="form.errors.email" class="error">{{ form.errors.email }}</div>
            </div>
            <div class="input-container">
                <label>Ваш коментар</label>
                <textarea name="text" class="textarea" v-model="form.text"></textarea>
                <div v-if="form.errors.text" class="error">{{ form.errors.text }}</div>
            </div>
            <button class="submit button">Додати коментар</button>
        </form>
    </div>
</template>

<script>
import {useForm} from "@inertiajs/vue3";


export default {
    name: "CommentModal",
    props: {
        errors: [Array, Object],
        parentId: [Number, String, Object],
        postId: [Number, String, Object],
        category: [String, Object],
    },
    setup(props) {
        const form = useForm({
            email: null,
            name: null,
            text: null,
            parentId: props.parentId,
            postId: props.postId,
            category: props.category,
        })

        return {
            form
        }
    }
}
</script>

<style scoped>

</style>
