<template>
    <div class="container-fluid">
        <div class="bredscrumps">
            <inertia-link href="/">Головна сторінка</inertia-link>
            <inertia-link v-if="blogCategory" :href="route('frontend.blog.index')">{{ $t('Блог') }}</inertia-link>
            <inertia-link :href="blogCategory.url">{{ blogCategory.title }}</inertia-link>
            <span>{{ blogPost.title }}</span>
        </div>
        <div class="blog-detail">
            <h1>{{ blogPost.h1 || blogPost.title }}</h1>
            <div class="d-lg-flex align-items-start">
                <div class="detail-info order-12">
                    <div class="info-top">

                        <div class="tags">
                            <div v-for="category in categories" class="tag health">{{ category.title }}</div>
                        </div>

                        <div class="date">{{ blogPost.published_at }}</div>
                    </div>
                    <div class="time d-flex align-items-center">
                        <span class="ic icon-blog"></span>
                        <span class="value">
                            {{ Math.ceil((stripTags(blogPost.content).length || 1000) / 1000) }} хвилин на прочитання
                        </span>
                    </div>
                    <div class="author d-flex align-items-center" v-if="blogPost.author_name">
                        <div class="author-photo">
                            <img src="/images/avatar.png" alt="" class="lozad">
                        </div>
                        <div class="auth-info">
                            <div class="anons">Автор</div>
                            <div class="name">{{ blogPost.author_name || blogPost.author.name }}</div>
                        </div>
                    </div>
                    <div class="author d-flex align-items-center" v-if="blogPost.censor_name">
                        <div class="author-photo">
                            <img src="/images/avatar.png" alt="" class="lozad">
                        </div>
                        <div class="auth-info">
                            <div class="anons">Цензор</div>
                            <div class="name">{{ blogPost.censor_name }}</div>
                        </div>
                    </div>
                    <div class="comments d-flex align-items-center">
                        <span class="ic icon-revs"></span>
                        <span class="value">{{ blogPost.reviews_count }} коментарі</span>
                    </div>
                    <div class="detail-share" v-if="false">
                        <div class="share-title">Поділитись статтею</div>
                        <div class="d-flex">
                            <a href="#" class="fb d-flex align-items-center justify-content-center"><span
                                class="ic icon-facebook"></span></a>
                            <a href="#" class="tw d-flex align-items-center justify-content-center"><span
                                class="ic icon-twitter"></span></a>
                            <a href="#" class="tg d-flex align-items-center justify-content-center"><span
                                class="ic icon-telegram"><span class="path1"></span><span
                                class="path2"></span></span></a>
                        </div>
                    </div>
                </div>
                <div class="detail-text">
                    <img :src="blogPost.image_url" alt="" class="detail-image lozad">

                    <div v-html="blogPost.content"></div>

                    <div class="detail-comments">
                        <div class="comments-top d-sm-flex align-items-center justify-content-between">
                            <div class="comments-title">Коментарі</div>
                            <button class="button add-comment" @click.prevent="showModal()">
                                Додати коментар
                            </button>
                        </div>
                        <div class="reviews-items">
                            <div v-if="blogPost.reviews.length === 0" style="margin-top: -30px">
                                Ще немає жодного коментаря
                            </div>
                            <div v-for="review in blogPost.reviews" class="review-item">
                                <div class="d-sm-flex align-items-center justify-content-between">
                                    <div class="review-item__name">{{ review.name }}</div>
                                    <div class="date">{{ review.created_at }}</div>
                                </div>
                                <div class="review-item__text">{{ review.text }}</div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-sm-flex">
                                        <button class="answer-link" @click.prevent="showModal(review.id)">
                                            Відповісти
                                        </button>
                                        <button class="more-answer" data-toggle="collapse"
                                                v-if="review.children.length"
                                                :href="`#answers-${review.id}`" role="button" aria-expanded="false"
                                                :aria-controls="`answers-${review.id}`">
                                            <span class="value">Показати відповіді</span> ({{ review.children.length }})
                                        </button>
                                    </div>
                                </div>

                                <div class="review-comments collapse" :id="`answers-${review.id}`">
                                    <div v-for="comment in review.children" class="review-item">
                                        <div class="d-sm-flex align-items-center justify-content-between">
                                            <div class="review-item__name">{{ comment.name }}</div>
                                            <div class="date">{{ comment.created_at }}</div>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="d-sm-flex">
                                                <div class="review-item__text">{{ comment.text }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="blog-list inner" v-if="relatedPosts.length > 0">
            <div class="blog-title">Вам може бути цікаво</div>
            <div class="row gutters-17 row-cols-1 row-cols-md-2 row-cols-lg-3">
                <div v-for="post in relatedPosts" class="col">
                    <div class="blog-item">
                        <a :href="post.url" class="item-image">
                            <img :src="post.image_url" :alt="post.alt" class="lozad">
                        </a>
                        <div class="blog-info d-flex align-items-center">
                            <a :href="blogCategory.url" class="tag health">{{ blogCategory.title }}</a>
                            <div class="date">{{ post.published_at }}</div>
                            <div class="comments d-flex align-items-center">
                                <span class="ic icon-revs"></span>
                                <span class="value">{{ post.reviews_count }}</span>
                            </div>
                        </div>
                        <a :href="post.url" class="blog-name">{{ post.title }}</a>
                        <div class="blog-author d-flex align-items-center" v-if="post.author_name">
                            <div class="author-photo">
                                <img src="/images/avatar.png" alt="" class="lozad">
                            </div>
                            <div class="author-name">{{ post.author_name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { stripHtml } from "../../../utils/string";
import { usePopLoader } from "../../scrollToTop";
export default {
    props: {
        blogCategory: Object,
        blogPost: Object,
        relatedPosts: [Object, Array],
        categories: [Object, Array],
    },
    setup() {
        const { popLoaderElement } = usePopLoader('pop-bg-loader loading', 100, 'header-container');
        return {
            popLoaderElement
        };
    },
    mounted() {
        let tagScript = document.createElement('script')
        tagScript.setAttribute('type', "application/ld+json")
        tagScript.setAttribute('id', "ld-json-blog-post")
        tagScript.text = `{
          "@context": "https://schema.org",
          "@type": "Article",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${this.blogPost.url}"
          },
          "headline": "${this.blogPost.title}",
          "description": "${this.blogPost.seo_description}",
          "image": "${this.blogPost.image_url}",
          "author": {
            "@type": "Person",
            "name": "${this.blogPost.author_name}"
          },
          "publisher": {
            "@type": "Organization",
            "name": "eTabletka",
            "logo": {
              "@type": "ImageObject",
              "url": "https://etabletka.ua/img/logo.svg"
            }
          },
          "datePublished": "${this.blogPost.published_at}"
        }`
        document.head.appendChild(tagScript)
    },
    beforeUnmount() {
        let tagScript = document.getElementById("ld-json-blog-post");
        tagScript.remove();
    },
    methods: {
        stripTags(html) {
            return stripHtml(html)
        },
        showModal(parentId = null) {

            this.$inertia.modal(this.route('frontend.review.add', ['blog_post', this.blogPost.id]), 'get', {
                category: this.blogCategory.slug,
                postId: this.blogPost.id,
                parentId: parentId,
            })
        }
    }
}
</script>

<style scoped>

</style>
