<template>
    <div class="modal-form">
        <div class="form-icon">
            <v-icon icon="form" />
        </div>
        <div class="form-title">Забули пароль</div>

        <form @submit.prevent="submit">
            <form-input v-model="form.phone"
                        :error="errors.phone"
                        :input-class="{ phone: true }"
                        :label="$t('Номер телефону')"
                        :is-phone="true"
                        :maxlength="19"
                        :required="true"
            />
            <button :disabled="form.processing" class="submit button">Надіслати код</button>
        </form>
        <div class="form-bottom">
            <button @click.prevent="$inertia.modal('/auth/login')" class="to-forgot form-link">Увійти</button>
            <button @click.prevent="$inertia.modal('/auth/register')" class="to-register form-link">Зареєструватись</button>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const VIcon = defineAsyncComponent(() => import("@/frontend/Components/Common/VIcon.vue"));
const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));

export default {
    components: { FormInput, VIcon },
    props: {
        phone: {},
    },
    data(){
        return {
            form: {
                phone: this.phone,
            },
        }
    },
    computed: {
        errors() {
            return this.$store.state.order.errors;
        },
    },
    mounted() {
        if(this.phone)
        {
            this.submit()
        }
    },
    methods: {
        submit(){
            this.$inertia.modal('/auth/password/generate', 'post', this.form)
        }
    }
}
</script>

<style scoped>

</style>
