<template>
    <div class="catalog-filter" :class="{open: filterOpen}">
        <button class="filter-button d-flex d-lg-none align-items-center justify-content-center"
                :class="{'is-active': filterOpen}"
                @click.prevent="toggleFilter()"
        >
            <VIcon icon="filter" />
            <span class="value">Фільтр</span>
        </button>
        <div class="filter-inner">
            <form>
                <div v-if="filters.categories?.length || filterParents.length" class="filter-container">
                    <div class="filter-name d-flex align-items-center justify-content-between " data-toggle="collapse"
                         data-target="#filter-categories" aria-expanded="false" aria-controls="collapseExample">
                        <span class="value">Категорія</span>
                        <span class="ic icon-ic_arrow"></span>
                    </div>
                    <div class="filter-container-inner collapse show" id="filter-categories">
                        <ul class="filter-catalog">
                            <li v-for="parent in filterParents" :key="'p'+parent.id">
                                <inertia-link :href="parent.url" class="active parent">
                                    {{ parent.name }}
                                </inertia-link>
                            </li>
                            <li v-if="filters.level > 1">
                                <span class="active current">
                                    {{ category.name }}
                                </span>
                            </li>
                            <li v-for="children in filters.categories">
                                <inertia-link :href="children.url">{{ children.name }}</inertia-link>
                            </li>
                        </ul>
                    </div>
                </div>


                <div class="filter-container" v-if="filters.price">
                    <div class="filter-name d-flex align-items-center justify-content-between " data-toggle="collapse"
                         data-target="#filter-prices" aria-expanded="false" aria-controls="collapseExample">
                        <span class="value">Ціна (грн)</span>
                        <span class="ic icon-ic_arrow"></span>
                    </div>
                    <div class="filter-container-inner collapse show" id="filter-prices">
                        <div class="slider-container">
                            <div class="inputs d-flex align-items-center justify-content-between">
                                <input class="slider-input" type="text" name="price" v-model="filterFormPrice.min"
                                       @keyup="changePrice('min', $event)"
                                       id="p_min">
                                <span class="line"></span>
                                <input class="slider-input" type="text" name="price" v-model="filterFormPrice.max"
                                       @keyup="changePrice('max', $event)"
                                       id="p_max">
                            </div>
                            <div id="filter-price" ref="sliderRef"></div>
                        </div>
                    </div>
                </div>

                <div v-if="filters.brands && filters.brands.length" class="filter-container">
                    <div class="filter-name d-flex align-items-center justify-content-between "
                         :class="{collapsed: !expanded}"
                         data-toggle="collapse" data-target="#filter-brands" aria-expanded="false">
                        <span class="value">{{ $t('Виробник') }}</span>
                        <span class="ic icon-ic_arrow"></span>
                    </div>
                    <div class="filter-container-inner collapse " :class="{show: filterFormBrands.length || expanded}"
                         id="filter-brands">
                        <div v-if="filters.brands.length > 9" class="search">
                            <input type="text" class="form-control" v-model="brandSearch">
                        </div>
                        <div class="checkboxes">
                            <div v-for="brand in selectedBrands" class="checkbox">
                                <input @change="sendFilterForm" v-model="filterFormBrands"
                                       type="checkbox"
                                       :id="`brand-${brand.id}`"
                                       :value="brand.id">
                                <label :for="`brand-${brand.id}`">{{ brand.name }}</label>
                            </div>
                            <div v-for="brand in filterBrands" class="checkbox">
                                <input @change="sendFilterForm" v-model="filterFormBrands"
                                       type="checkbox"
                                       :id="`brand-${brand.id}`"
                                       :value="brand.id">
                                <label :for="`brand-${brand.id}`">{{ brand.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="filters.badges && filters.badges.length" class="filter-container">
                    <div class="filter-name d-flex align-items-center justify-content-between "
                         :class="{collapsed: !expanded}"
                         data-toggle="collapse" data-target="#filter-badge" aria-expanded="false">
                        <span class="value">{{ $t('Мітки') }}</span>
                        <span class="ic icon-ic_arrow"></span>
                    </div>
                    <div class="filter-container-inner collapse " :class="{show: filterFormBadges.length || expanded}"
                         id="filter-badge">
                        <div class="checkboxes">
                            <div v-for="badge in filters.badges" class="checkbox">
                                <input @change="sendFilterForm" v-model="filterFormBadges"
                                       type="checkbox"
                                       :id="`badges-${badge.id}`"
                                       :value="badge.id">
                                <label :for="`badges-${badge.id}`">{{ badge.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-if="filters.characteristics && filters.characteristics.length">
                    <div v-for="characteristic of filters.characteristics"
                         class="filter-container">
                        <div class="filter-name d-flex align-items-center justify-content-between"
                             :class="{collapsed: !expanded}"
                             data-toggle="collapse" :data-target="`#filter-char-${characteristic.id}`"
                             aria-expanded="false">
                            <span class="value">{{ characteristic.name }}</span>
                            <span class="ic icon-ic_arrow"></span>
                        </div>
                        <div class="filter-container-inner collapse "
                             :class="{show: filterFormCharacteristics[characteristic.id]?.length || expanded}"
                             :id="`filter-char-${characteristic.id}`">
                            <div v-if="characteristic.values.length > 9" class="search">
                                <input type="text" class="form-control"
                                       v-model="characteristicSearch[characteristic.id]">
                            </div>
                            <div class="checkboxes">

                                <div v-for="value in filterValues(characteristic)" class="checkbox">
                                    <input type="checkbox"
                                           v-model="filterFormCharacteristics[characteristic.id]"
                                           @change="sendFilterForm"
                                           :id="characteristic.id + '_' + value.value"
                                           :value="value.value">
                                    <label :for="characteristic.id + '_' + value.value">{{ value.text }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <div v-if="filters.discounts && filters.discounts.length" class="filter-container">
                    <div class="filter-name d-flex align-items-center justify-content-between"
                         :class="{collapsed: !expanded}"
                         data-toggle="collapse" data-target="#filter-discounts" aria-expanded="false">
                        <span class="value">{{ $t('Знижки') }}</span>
                        <span class="ic icon-ic_arrow"></span>
                    </div>
                    <div class="filter-container-inner collapse "
                         :class="{show: filterFormDiscounts.length || expanded}"
                         id="filter-discounts">
                        <div class="checkboxes">
                            <div v-for="discount in filters.discounts" class="checkbox">
                                <input @change="sendFilterForm" v-model="filterFormDiscounts"
                                       type="checkbox"
                                       :id="`discount-${discount.value}`"
                                       :value="discount.value">
                                <label :for="`discount-${discount.value}`">{{ discount.text }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="filter-container">
                    <button class="button podbor" @click.prevent="sendFilterFormMobile()">Підібрати</button>
                </div>
            </form>
            <button class="close d-xl-none" @click.prevent="toggleFilter(false)"></button>
        </div>
    </div>

</template>

<script>

export default {
    name: 'CatalogFilter',
    props: {
        expanded: Boolean,
    },
    data() {
        return {
            searchTimeOut: 0,
            filterOpen: false
        }
    },
    computed: {
        filterFormBrands: {
            get() {
                return this.$store.state.catalog.filterForm.brands;
            },
            set(value) {
                this.$store.commit('catalog/UPDATE_FILTER_FORM', {brands: value})
            }
        },
        filterFormBadges: {
            get() {
                return this.$store.state.catalog.filterForm.badges;
            },
            set(value) {
                this.$store.commit('catalog/UPDATE_FILTER_FORM', {badges: value})
            }
        },
        filterFormDiscounts: {
            get() {
                return this.$store.state.catalog.filterForm.discounts || [];
            },
            set(value) {
                this.$store.commit('catalog/UPDATE_FILTER_FORM', {discounts: value})
            }
        },
        filterFormPrice() {
            return this.$store.state.catalog.filterForm.price;
        },
        filterFormCharacteristics: {
            get() {
                return this.$store.state.catalog.filterForm.characteristics;
            },
            set(value) {
                this.$store.commit('catalog/UPDATE_FILTER_FORM', {characteristics: value})
            }
        },
        brandSearch: {
            get() {
                return this.$store.state.catalog.brandSearch
            },
            set(value) {
                this.$store.commit('catalog/UPDATE_BRAND_SEARCH', value)
            }
        },
        characteristicSearch: {
            get() {
                return this.$store.state.catalog.characteristicSearch
            },
            set(value) {
                this.$store.commit('catalog/UPDATE_CHARACTERISTIC_SEARCH', value)
            }
        },
        category() {
            return this.$store.state.catalog.category;
        },
        filterForm: {
            get() {
                return this.$store.state.catalog.filterForm;
            },
            set(value) {
                this.$store.commit('catalog/UPDATE_FILTER_FORM', value)
            }
        },
        filters() {
            return this.$store.state.catalog.filters;
        },
        filterValues() {
            return (characteristic) => {
                let searchValue = this.characteristicSearch[characteristic.id].toLowerCase()
                return (characteristic.values || []).filter((value) => {
                    return (value.text.toLowerCase().indexOf(searchValue) > -1) || (value.value.toLowerCase().indexOf(searchValue) > -1)
                })
            }
        },
        selectedBrands() {
            return this.filters.brands.filter((brand) => {
                return this.filterFormBrands.indexOf(brand.id.toString()) !== -1 || this.filterFormBrands.indexOf(brand.id) !== -1;
            })
        },
        filterBrands() {
            let searchValue = this.brandSearch.toLowerCase()

            return this.filters.brands.filter((brand) => {
                return (brand.name.toLowerCase().indexOf(searchValue) > -1) && (this.filterFormBrands.indexOf(brand.id.toString()) === -1 && this.filterFormBrands.indexOf(brand.id) === -1);
            })
        },
        filterParents() {
            return this.$store.state.catalog.parents || [];
        }
    },

    mounted() {

        let vm = this;


        $(".filter-container .checkboxes").mCustomScrollbar({
            theme: "dark"
        });

        if (vm.$refs.sliderRef) {
            $(vm.$refs.sliderRef).slider({
                animate: "slow",
                range: true,
                min: vm.filters.price?.min || 0,
                max: vm.filters.price?.max || 0,
                values: [vm.filterFormPrice.min || 0, vm.filterFormPrice.max || vm.filters.price.max],
                create: function () {
                    vm.$store.commit('catalog/UPDATE_FILTER_FORM', {
                        price: {
                            min: number_format($(this).slider("values", 0), 0, '.', ''),
                            max: number_format($(this).slider("values", 1), 0, '.', ''),
                        }
                    })
                },

                slide: function (event, ui) {
                    vm.$store.commit('catalog/UPDATE_FILTER_FORM', {
                        price: {
                            min: number_format($(this).slider("values", 0), 0, '.', ''),
                            max: number_format($(this).slider("values", 1), 0, '.', ''),
                        }
                    })
                },

                change: function (event, ui) {

                    vm.$store.commit('catalog/UPDATE_FILTER_FORM', {
                        price: {
                            min: number_format($(this).slider("values", 0), 0, '.', ''),
                            max: number_format($(this).slider("values", 1), 0, '.', ''),
                        }
                    });
                    vm.sendFilterForm();
                }
            });
        }

        // Filters accordion
        let $filterBlocks = $('.filter-name[data-toggle="collapse"]');

        $filterBlocks.click(function (e) {
            let _block ;
            if($(e.target).is('.filter-name[data-toggle="collapse"]')){
                _block = e.target
            } else {
                _block = $(e.target).parents('.filter-name[data-toggle="collapse"]')[0]
            }

            $filterBlocks.not(_block).each(function (i,filterBlock){
                let target = $($(filterBlock).data('target'))
                if (
                    $(filterBlock).data('target') !== '#filter-prices'
                    && !$(filterBlock).hasClass('collapsed')
                    && !$(target).find('input:checkbox:checked').length
                ) {
                    $(filterBlock).click()
                }
            })
        });
    },

    methods: {
        toggleFilter(open = null) {
            if (open !== null) {
                this.filterOpen = open;
            } else {
                this.filterOpen = !this.filterOpen;
            }

            if (this.filterOpen) {
                $('body').addClass("filter-open");
            } else {
                $('body').removeClass("filter-open");
            }
        },
        sendFilterForm(e) {
            this.$store.dispatch('catalog/loadProducts');
        },
        sendFilterFormMobile(e) {
            this.toggleFilter(false);
            this.$store.dispatch('catalog/loadProducts');
        },
        changePrice(key, event) {
            const self = this;
            clearTimeout(this.searchTimeOut);
            self.searchTimeOut = setTimeout(function () {
                let val = parseInt(event.target.value) || 0;

                if (self.filterFormPrice[key] !== val) {
                    const price = {};
                    price[key] = val;
                    price[key === 'min' ? 'max' : 'min'] = self.filterFormPrice[key === 'min' ? 'max' : 'min'];
                    self.$store.commit('catalog/UPDATE_FILTER_FORM', {
                        price: price
                    });
                    $(self.$refs.sliderRef).slider("values", [price.min, price.max]);
                    self.sendFilterForm();
                }
            }, 500)

        }
    },
}
</script>

<style scoped lang="scss">
.close {
    position: fixed;

    &:before {
        color: #fff;

    }
}
</style>
