<template>
    <div class="modal-form">
        <div class="form-icon">
            <VIcon icon="form" />
        </div>
        <div class="form-title">Введіть код із SMS</div>
        <div class="form-anons">Ми вислали вам код на номер {{ phone }}</div>

        <form @submit.prevent="submit" class="form no-send">
            <form-input v-model="form.auth_code"
                        :error="errors.auth_code"
                        :required="true"
                        placeholder="Введіть 4-значний код із смс"
            />
            <button class="submit button ">Продовжити реєстрацію</button>
        </form>
        <div class="form-bottom">
            <div class="form-bottom__text" :class="{disabled: !!resetTimer}">
                Якщо код не надійшов вам за <span>{{ resetTimer }} сек. </span>

                <a @click.prevent="resendToken" href="#">Надіслати повторно</a>

            </div>
            <button @click.prevent="resetPhoneNumber" class="to-register form-link">
                Надіслати код на інший номер
            </button>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const VIcon = defineAsyncComponent(() => import("@/frontend/Components/Common/VIcon.vue"));
const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));

export default {
    components: { FormInput, VIcon },
    props: {
        errors: {},
        phone: {},
        code: {},
        code_generated_at: {},
        rateLimit: {},
    },
    data() {
        return {
            form: {
                phone: this.phone,
                auth_code: '',
            },

            resetTimer: this.rateLimit
        }
    },
    mounted() {
        // console.log('CODE', this.code)
        if (!isProd) {
            this.form.auth_code = this.code
            this.$forceUpdate()
        }

        let vm = this

        let interval = setInterval(() => {
            if(--vm.resetTimer < 1){
                clearInterval(interval)
            }
        }, 1000);
    },
    methods: {
        submit(){
            this.$inertia.modal('/auth/register/verify', 'post', this.form)
        },

        resendToken(){
            if(!this.resetTimer){
                this.$inertia.modal('/auth/register/resend', 'put', this.form)
            } else {

                this.$inertia.modal('/auth/register/resend', 'put', this.form)
            }
        },

        resetPhoneNumber(){
            this.$inertia.modal('/auth/register/reset', 'delete')
        }
    }
}
</script>

<style scoped>

</style>
