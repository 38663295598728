<template>
    <div class="modal-form">
        <div class="form-icon">
            <VIcon icon="form" />
        </div>
        <div class="form-title">Авторизація</div>

        <form @submit.prevent="submit">

            <form-input v-model="form.phone"
                        :error="errorsState.phone"
                        :input-class="{ phone: true }"
                        :label="$t('Номер телефону')"
                        :is-phone="true"
                        :maxlength="19"
                        :required="true"
                        @input="inputSymbols()"
            />

            <form-input v-model="form.password"
                        :error="errorsState.password"
                        type="password"
                        name="password"
                        autocomplete="current-password"
                        placeholder="Введіть ваш пароль"
                        @input="inputSymbols()"
            />

            <button :disabled="formFilled || form.processing" class="submit button">Увійти</button>
            <div v-if="errors.phone && showError" class="input-container err error ok">
                <div class="error">{{errors.phone}}</div>
            </div>
        </form>
        <div class="form-bottom">
            <button @click.prevent="$inertia.modal('/auth/password')" class="to-forgot form-link">Забули пароль?</button>
            <button @click.prevent="$inertia.modal('/auth/register')" class="to-register form-link">Зареєструватись</button>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const VIcon = defineAsyncComponent(() => import("@/frontend/Components/Common/VIcon.vue"));
const FormInput = defineAsyncComponent(() => import("@/frontend/Components/Form/FormInput.vue"));

export default {
    components: { FormInput },
    props: {
        errors: [Array, Object],
        old: Object,
        redirect: String,
    },
    data() {
        return {
            showError: true,
            form: {
                phone: '',
                password: '',
                redirect: this.redirect,
            },
        }
    },
    computed: {
        formFilled() {
            return !(this.form.phone.length >= 9 && this.form.password.length >= 6)
        },
        errorsState() {
            return this.$store.state.order.errors;
        },
    },
    methods: {
        submit() {
            this.$inertia.modal('/auth/login', 'post', this.form, {
                only: ['user']
            })
            this.showError = true
        },
        inputSymbols() {
            if (this.errors.phone) {
                this.showError = false
            }
        }
    }
}
</script>

<style scoped>
.modal-form {
    max-width: 540px;
}
</style>
